<template>
    <div class="capa-revista" id="home">
       <div class="capa-revista-left">
            <div class="titulo-capa-revista">
                <p class="first">TEMU</p>
                <p style="display: none;" class="imprevisto"></p>
                <p class="second">NO BRASIL</p>
                <p style="display: none;" class="imprevisto third"></p>
                
            </div>
            <div class="fundo-revista">
                <div class="capa-revista">
                    <a><img src="./../assets/imgs/imgs_82/332x454px.png" loading=lazy></a>
                </div>
            </div>
        </div>
        
        <div class="capa-revista-right">
            <div class="img-capa-2">
                <a><img src="./../assets/imgs/imgs_82/430x590px.png" loading=lazy></a>
            </div>
            <div class="img-left">
            
            </div>
            <div class="info-assinante">
                <h2>Edição impressa exclusiva para assinantes</h2>
            </div>
        </div>
    </div>
</template>

<script>

export default {   
}
</script>
<style scoped>
    .capa-revista{
        margin-top: 65px;
        background-image: url("./../assets/imgs/imgs_77/1920x793px.png");
        height: 585px;
        display: flex;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .titulo-capa-revista{
        position: absolute;
        z-index: 2;
        width: 40%;
        transform: translate(20%,40%);
        min-width: 495.3px;
        line-height: 105px;
        display: flex;
        flex-wrap: wrap;
        max-width: 1267px;
        flex-direction: column;
    }
    .titulo-capa-revista p{
        text-transform: uppercase;
        color: #fff;
        font-size: 80px;
        margin: 0px;
        background-color: rgb(4 33 44 / 75%);
        text-align: center;
        height: 98px;
        line-height: 98px;
        width: fit-content;
    }
    .titulo-capa-revista .first::after{
        content: "Revista Bimestral";
        position: absolute;
        background-color: #fff;
        font-size: 21px;
        top: -27px;
        /*right: 100px;*/
        color: #000;
        height: 25px;
        width: 144px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        transform: translate(0%,7%);
        line-height: 1px;
    }
    .titulo-capa-revista .second::before{
        content: "Edição 82";
        position: absolute;
        background-color: #fff;
        font-size: 21px;
        color: #000;
        height: 35px;
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        top: 196px;
        line-height: 1px;
    }
    .titulo-capa-revista .second{
        
    }
    .capa-revista .fundo-revista{
        background-image: url("../assets/imgs/imgs_77/313x442px.png");
        height: 650px;
        background-repeat: no-repeat;
        width: 75%;
        position: relative;
        max-width: 505.19px;
        z-index: 0;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        background-size: cover;
    }
    .capa-revista .capa-revista{
        position: relative;
        background-image: initial;
        width: 245px;
        height: 315px;
        margin-right: 51px;
    }
    .capa-revista .capa-revista img{
        width: 100%;
    }
    .img-left{
        background-image: url("./../assets/imgs/imgs_77/1185x407px.png");
        width: 100%;
        height: 55%;
        position: relative;
        top: 188px;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .capa-revista-right{
        width: 55%;
    }
    .capa-revista-left{
        width: 45%;
        display: flex;
        justify-content: flex-end;
    }
    .img-capa-2{
       position: absolute;
       transform: translate(94%,0%);
       width: 28%;
       z-index: 1;
       max-width: 425.38px;
    }
    .img-capa-2 img{
        width: 76%;
    }
    .info-assinante{
        position: relative;
        top: 211px;
        margin-left: 35px;
    }
    .info-assinante h2{
       text-transform: uppercase;
       color: #000;
       font-size: 26px;
    }
    .imprevisto{
        padding-left: 14px;
    }
   
   @media(max-width: 947px){
        .titulo-capa-revista{
            min-width: auto;
            width: 66%;
            transform: translate(44%,172%);
            height: 68px;
            line-height: 59px;
            z-index: 1;
        }
        .titulo-capa-revista .first::after{
            transform: translate(246%,7%);
        }
        .titulo-capa-revista .second::before{
            
        }
         .titulo-capa-revista p{
            font-size: 39px
        }
    }
    @media(max-width: 845px){
        .titulo-capa-revista{
            min-width: auto;
            width: 77%;
            height: 68px;
            line-height: 59px;
            transform: translate(49%,308%);
        }
        .titulo-capa-revista .first::after{
            transform: translate(246%,7%);
            display: none !important;
        }
        .titulo-capa-revista .second::before{
            display: none !important;
        }
         .titulo-capa-revista p{
            font-size: 39px
        }
    }
    @media(max-width: 775px){
     .titulo-capa-revista p{
          height: 56px;
          line-height: 59px;
           font-size: 29px;
     }
     .titulo-capa-revista .first::after {
         transform: translate(274%,22%);
         height: 22px;
         width: 100px;
         font-size: 12px;
     }
     .titulo-capa-revista .second::before {
         height: 22px;
         width: 46px;
         font-size: 12px;
         top: 112px;
     }
    }
    @media(max-width: 594px){
      .titulo-capa-revista{
          min-width: auto;
          transform: translate(5%,220%);
          line-height: 46px;
          z-index: 1;
        width: 79%;
      }
      .titulo-capa-revista p{
        font-size: 35px;
        text-align: inherit;
        height: auto;
        line-height: 48px;
      }.titulo-capa-revista .third{
        width: 74%;
      }
      .capa-revista-left{
        width: 100%;
        justify-content: center;
        z-index: 1;
       
      }
      .capa-revista-right{
        width: 100%;
      }
      .capa-revista .fundo-revista {
        height: 385px;
        z-index: 0;
        width: 85%;
        transform: translate(-9%,0%);
        z-index: 0;
    }
      .capa-revista{
          width: auto;
          margin: 0px;
          flex-direction: column;
            height: 795px;
      }
      .capa-revista .capa-revista{
          top: 245px;
          margin: 0px auto;
          transform: translate(12%,40%);

      }
      .info-assinante h2{
          display: none;
      }
      .img-left{
          position: absolute;
          top: 634px;
          height: 25%;
      }
      .img-capa-2{
          transform: translate(0%,-266%);
          z-index: 0;
          right: 0px;
          display: none;
      }
      .img-capa-2 img{
          width: 100%;
      }
      .titulo-capa-revista .first::after{
          top: 256px;
          font-size: 14px;
          height: 22px;
          width: 99px;
          left: 0px;
            transform: translate(0%,-71%);
      }
      .titulo-capa-revista .second::before{
          font-size: 14px;
          height: 22px;
          width: 55px;
          top: -22px;
    }
    .imprevisto{
        padding-left: 0px;
    }
     @media(max-width: 290px){
         .titulo-capa-revista p{
            font-size: 29px;
         }
     }
}
</style>

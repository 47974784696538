export default {
  materia: [
    {
      titulo: "CAPA",
      resumo: "Temu no Brasil: quem tem medo das gigantes asiáticas?",
      imgAutor: require("../assets/imgs/autores/julia.rondinelli.png"),
      nomeAutor: "Giuliano Gonçalves e Júlia Rondinelli",
      cargoAutor: "E-Commerce Brasil",
      conteudo:
        "A concorrência não é de hoje. Na verdade, ela é secular, mas todos os dias surge um novo medo que o varejo brasileiro precisa encarar ao invés de varrer para baixo do tapete. A Temu é a bola da vez. O conglomerado de marcas entrou no Brasil com um grave estrondo, mostrando que não basta ser um gigante, é preciso manter a posição. Em uma época em que vimos a ascensão de AliExpress, Shein e Shopee, a entrada de um novo concorrente no país não é mais uma surpresa, mas pode trazer alguns aprendizados para as empresas nacionais e internacionais.",
      tempLeitura: "2",
      imgMateria: require("../assets/imgs/imgs_82/500x500px-CAPA.png"),
      active: true,
      viewModal: false,
    },
    {
      titulo: "ENTREVISTA",
      imgAutor: require("../assets/imgs/autores/lucas.kina.jpg"),
      nomeAutor: "Lucas Kina",
      resumo: "Remediando aos poucos",
      cargoAutor: "E-Commerce Brasil",
      conteudo:"Alguns meses após as enchentes no Rio Grande do Sul, a Panvel, companhia farmacêutica líder na região sul, mostra como superou o período de dificuldades e o que tem feito para ajudar o estado.  Um boletim da Defesa Civil gaúcha, divulgado na primeira quinzena de maio, informou que mais de 442 mil pessoas foram desalojadas, além de 97% dos municípios do estado (425 de 487) terem sido alagados. Neste momento, esses e outros números traduzem pouco ou nada a respeito do que o povo gaúcho passou, bem como o que os varejistas locais fizeram para superar um período tão complicado.",
      tempLeitura: "2",
      imgMateria: require("../assets/imgs/imgs_82/500x500px-ENTREVISTA.png"),
      active: true,
      viewModal: false,
    },
    {
      titulo: "CASE",
      imgAutor: require("../assets/imgs/autores/helena.jpg"),
      nomeAutor: "Helena Canhoni",
      resumo: "Do quintal para o sofá da sala",
      cargoAutor: "E-Commerce Brasil",
      conteudo:
        'Clubes de assinatura são uma oportunidade para construir relações mais duradouras e surpreender aqueles que sempre nos recebem com alegria: os amigos de quatro patas. Então, por que não unir o útil ao agradável? À frente das tendências, a Petiko nasceu no final de 2014, conversando com os consumidores que incluíam os pets nas famílias e no orçamento das comprinhas. Luciano Miranda, CEO da Petiko, traçou as pegadas dessa trajetória para a Revista 82 do E-Commerce Brasil.',
      tempLeitura: "2",
      imgMateria: require("../assets/imgs/imgs_82/500x500px.png"),
      active: true,
      viewModal: false,
    },
  ],
};
